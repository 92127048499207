// extracted by mini-css-extract-plugin
export var info = "InfoCard-module--info--HV4Jq";
export var infoHeading = "InfoCard-module--infoHeading--736sY";
export var infoContent = "InfoCard-module--infoContent--E2Tez";
export var infoIcon = "InfoCard-module--infoIcon--2MxZ_";
export var infoButton = "InfoCard-module--infoButton--2BCAq";
export var infoCardLink = "InfoCard-module--infoCardLink--2oVPH";
export var horizontal = "InfoCard-module--horizontal--1DAbg";
export var horizontalContainer = "InfoCard-module--horizontalContainer--1CR4y";
export var horizontalHeading = "InfoCard-module--horizontalHeading--T0chM";
export var horizontalSubheading = "InfoCard-module--horizontalSubheading--3VSad";
export var horizontalBtnText = "InfoCard-module--horizontalBtnText--2rcOL";
export var horizontalIcon = "InfoCard-module--horizontalIcon--1ZrRo";