import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Icon, iconsList } from "../../Icons";
import { Card } from "../Card";
import { Link } from "../../../sharedComponents/Link";
import { HeadingAsElement, HeadingSize } from "../../Heading";

import * as styles from "./InfoCard.module.scss";

const InfoCard = ({
	className,
	icon,
	header,
	headerAs,
	headerSize,
	subheading,
	variant,
	content,
	link: button,
	LinkComponent,
}) => (
	<Card className={cx(variant !== "horizontal" && styles.info, className)}>
		{variant === "horizontal" ? (
			<div className={styles.horizontal}>
				{icon && <Icon name={icon} className={cx(styles.horizontalIcon)} />}
				<Link to={button?.to} target={button?.target} className={cx(styles.horizontalContainer)}>
					<div className={cx(styles.horizontalSubheading)}>{subheading}</div>
					<div>
						{button && <span className={cx(styles.horizontalBtnText)}>{button?.text}</span>}
					</div>
				</Link>
			</div>
		) : (
			<>
				{icon && <Icon name={icon} className={cx(styles.infoIcon)} />}
				<Card.Header
					as={headerAs}
					size={headerSize}
					align="center"
					className={cx(styles.infoHeading)}
				>
					{header}
				</Card.Header>
				{content && <Card.Content className={cx(styles.infoContent)}>{content}</Card.Content>}
				{button && (
					<div>
						<Card.Button
							className={cx(styles.infoButton)}
							icon={button?.icon}
							iconPlacement={button?.iconPlacement}
							to={button?.to}
							target={button?.target}
							label={button?.text}
						>
							{button?.text}
						</Card.Button>
					</div>
				)}
				{button?.to && (
					<Card.AsLink
						LinkComponent={LinkComponent}
						link={button.to}
						target={button?.target}
						text={button.text}
						className={styles?.infoCardLink}
					/>
				)}
			</>
		)}
	</Card>
);

InfoCard.propTypes = {
	className: PropTypes.string,
	header: PropTypes.string,
	headerAs: PropTypes.oneOf(Object.keys(HeadingAsElement)),
	headerSize: PropTypes.oneOf(Object.keys(HeadingSize)),
	content: PropTypes.string,
	subheading: PropTypes.string,
	icon: PropTypes.string,
	link: PropTypes.shape({
		to: PropTypes.string,
		target: PropTypes.string,
		icon: PropTypes.oneOf(iconsList),
		iconPlacement: PropTypes.oneOf(["right", "left"]),
		className: PropTypes.string,
		text: PropTypes.string,
	}),
	LinkComponent: PropTypes.elementType,
	variant: PropTypes.oneOf(["horizontal"]),
};

InfoCard.defaultProps = {
	header: undefined,
	headerAs: "h3",
	headerSize: "h3",
	className: "",
	content: null,
	subheading: null,
	link: undefined,
	icon: undefined,
	LinkComponent: undefined,
	variant: undefined,
};

export default InfoCard;
export { InfoCard };
