/* eslint-disable no-dupe-keys */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import * as dayjs from "dayjs";
import { RCL as useTranslation } from "../../RCL";
import { Heading } from "../../Heading";
import { openLinkWithPostMethod } from "../../../utils/open-link-with-post-method";

// Field Components
import { CruiseModeField } from "./CruiseModeField";
import { DestinationField } from "./DestinationField";
import { CruiseLineField } from "./CruiseLineField";
import { DateField } from "./DateField";
import { DurationField } from "./DurationField";
import { PortField } from "./PortField";
import { Button } from "../../Button";

import * as parentStyles from "../SearchBox.module.scss";
import * as styles from "./Cruises.module.scss";

const cx = classNames.bind(styles);

const Cruises = ({ language, config }) => {
	const showOceanRiverButton = config?.showOceanRiverButton;
	const GetDictionary = _key => useTranslation({ searchKey: _key });

	// Destination selection
	const [destinationSelection, setDestination] = useState(null);
	const [isValidDestination, setIsValidDestination] = useState(true);

	// Cruiseline selection
	const [cruiseLineSelection, setCruiseLine] = useState(null);
	const [isValidCruiseLine, setIsValidCruiseLine] = useState(true);

	// Duration selection
	const [durationSelection, setDuration] = useState(null);
	const [durationData, setDurationData] = useState([]);
	const [isValidDuration, setIsValidDuration] = useState(true);

	// Departure Port selection
	const [portSelection, setPort] = useState(null);
	const [isValidPort, setIsValidPort] = useState(true);

	// Date state
	const [departureDay, setDepartureDay] = useState(
		dayjs(config.calendar.startDate).startOf("day").add(config.calendar.defaultMin, "days")
	);

	const dictionary = {
		when: useTranslation({ searchKey: "when" }),
		// Duration options
		"0-9999-duration": useTranslation({ searchKey: "0-9999-duration" }),
		"1-2-duration": useTranslation({ searchKey: "1-2-duration" }),
		"3-6-duration": useTranslation({ searchKey: "3-6-duration" }),
		"7-9-duration": useTranslation({ searchKey: "7-9-duration" }),
		"10-14-duration": useTranslation({ searchKey: "10-14-duration" }),
		"15-9999-duration": useTranslation({ searchKey: "15-9999-duration" }),
		// Cruise mode options
		"cruise-ocean": useTranslation({ searchKey: "cruise-ocean" }),
		"cruise-river": useTranslation({ searchKey: "cruise-river" }),
	};

	/* /////////////////////////////////////////////////////////////
	// Cruise mode radio toggle
	///////////////////////////////////////////////////////////// */

	const [cruiseMode, setCruiseMode] = useState("ocean");
	const [isValidCruiseMode, setIsValidCruiseMode] = useState(true);
	const cruiseModeData = [
		{
			id: "ocean",
			label: dictionary["cruise-ocean"],
			value: "ocean",
		},
		{
			id: "river",
			label: dictionary["cruise-river"],
			value: "river",
		},
	];

	/* /////////////////////////////////////////////////////////////
	// OBJECT FLATTEN FUNCTION
	///////////////////////////////////////////////////////////// */
	function flattenObject(obj) {
		let flatSearchParams = "";
		// eslint-disable-next-line no-restricted-syntax
		for (const key in obj) {
			if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
				// eslint-disable-next-line no-restricted-syntax
				for (const _childKey in obj[key]) {
					if (_childKey.includes("[]")) {
						const newKey = _childKey.replace(/\[|\]/g, "");
						flatSearchParams += `${key}[${newKey}][]=${obj[key][_childKey]}&`;
					} else if (_childKey.includes("[0]")) {
						const newKey = _childKey.replace(/\[0\]/g, "");
						flatSearchParams += `${key}[${newKey}][0]=${obj[key][_childKey]}&`;
					} else {
						flatSearchParams += `${key}[${_childKey}]=${obj[key][_childKey]}&`;
					}
				}
			} else if (flatSearchParams.endsWith("&")) {
				flatSearchParams += `${key}=${obj[key]}`;
			} else {
				flatSearchParams += `&${key}=${obj[key]}`;
			}
		}
		return flatSearchParams;
	}

	const handleFormSubmission = async (event, openInNewTab = false) => {
		event.preventDefault();

		/* /////////////////////////////////////////////////////////////
	    // PARAMETERS
	    ///////////////////////////////////////////////////////////// */
		const ckey = process.env.GATSBY_TRAVEL_BRANDS_AGENCY_CKEY;
		const duration = durationSelection?.value.split("-") || [0, 9999];
		const maxDuration = duration[1];
		const minDuration = duration[0];
		const selectedDestination = destinationSelection?.value;
		const selectedCruiseLine = cruiseLineSelection?.value;
		const selectedPort = portSelection?.value;
		const startDate = departureDay?.startOf("month").format("MM/DD/YYYY");
		const plCode = "SOV";
		const usernameAndPassword = `${process.env.GATSBY_TRAVEL_BRANDS_AGENCY_PHONE}${process.env.GATSBY_TRAVEL_BRANDS_AGENCY_NAME}`;
		const XSPlus = process.env.GATSBY_TRAVEL_BRANDS_AGENCY_XSPLUS;
		const lang = language === "fr" ? "fr_CA" : "en_CA";

		const payload = new FormData();
		payload.append("agencyPhone", process.env.GATSBY_TRAVEL_BRANDS_AGENCY_PHONE);
		payload.append("agencyName", process.env.GATSBY_TRAVEL_BRANDS_AGENCY_NAME);
		payload.append("plCode", plCode);

		let searchParams = "";
		let apiRequest = "";

		const headerLocale = language === "fr" ? "header-fr" : "header-en";
		const footerLocale = language === "fr" ? "footer-fr" : "footer-en";
		const ssoRevelex = encodeURIComponent("sso_parameters[RevelexID]");

		const searchParamsObjectOcean = {
			search: {
				search_type: "cruise_only",
				duration_max: maxDuration,
				duration_min: minDuration,
				start_date: startDate,
				end_date: "",
				"destination_ids[]": selectedDestination,
				"vendor_ids[]": selectedCruiseLine,
				"ship_ids[]": "",
				number_of_cabins: 3,
				"plan_code[]": "",
				number_of_cabins: 1,
				"departure_port_ids[]": selectedPort,
			},
			LANG: lang,
			"customer_parameters[tb_header_url]": `${process.env.GATSBY_TRAVEL_BRANDS_HEADER_FOOTER_BASE_LINK}-${headerLocale}`,
			"customer_parameters[tb_footer_url]": `${process.env.GATSBY_TRAVEL_BRANDS_HEADER_FOOTER_BASE_LINK}-${footerLocale}`,
			ckey,
			"customer_parameters[sid]": XSPlus,
		};

		searchParams = flattenObject(searchParamsObjectOcean);

		apiRequest = `${
			process.env.GATSBY_TRAVEL_BRANDS_SEARCH_URL
		}?clear=all&LANG=${lang}&plCode=${plCode}&cust=${usernameAndPassword}&page=${
			cruiseMode === "river" ? "cruise-deals" : ""
		}&ckey=${ckey}&hatoken=${usernameAndPassword}&${ssoRevelex}=100087&searchParams=${encodeURIComponent(
			searchParams
		)}&cruise_type=${cruiseMode}`;

		/* /////////////////////////////////////////////////////////////
	    // Remove radio-ocean / radio-river from payload
	    ///////////////////////////////////////////////////////////// */
		payload.append("searchParams", `${searchParams}`);

		const payloadObject = Object.fromEntries(payload);
		let keyToRemove = "radio-ocean";
		if (cruiseMode === "river") {
			keyToRemove = "radio-river";
		}
		// eslint-disable-next-line no-prototype-builtins
		if (payloadObject.hasOwnProperty(keyToRemove)) {
			delete payloadObject[keyToRemove];
		} else {
			console.warn(`Key "${keyToRemove}" does not exist in the object`);
		}

		/* /////////////////////////////////////////////////////////////
	    // Calling form submission function
	    ///////////////////////////////////////////////////////////// */
		if (openInNewTab) {
			openLinkWithPostMethod(apiRequest, payloadObject, "_blank");
		} else {
			openLinkWithPostMethod(apiRequest, payloadObject);
		}
	};

	const setupCruiseData = () => {
		// Set up duration data
		const _duration = [];
		for (let i = 0; i < config.duration.length; i++) {
			const durationItem = {
				id: config.duration[i],
				value: config.duration[i],
				label: dictionary[`${config.duration[i]}-duration`] || `${config.duration}-duration`,
			};
			_duration.push(durationItem);
		}

		setDurationData(_duration);
	};

	useEffect(() => {
		setupCruiseData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setCruiseLine(null);
		setPort(null);
	}, [destinationSelection, cruiseMode]);

	return (
		<React.Fragment>
			<Heading as="div" size="h2">
				{GetDictionary("sb-cruises-heading")}
			</Heading>
			{GetDictionary("sb-cruises-subheading") && (
				<p className={cx(parentStyles.subheading)}>{GetDictionary("sb-cruises-subheading")}</p>
			)}
			<div id="sov-search-box">
				<form
					id="cruise-form"
					acceptCharset="UTF-8"
					noValidate
					className={cx("searchForm")}
					onSubmit={handleFormSubmission}
				>
					{showOceanRiverButton && (
						<div className={cx("formRow", "cruiseMode")}>
							<div className={cx("formColumn")}>
								<CruiseModeField
									id="cruise-mode"
									language={language}
									data={cruiseModeData}
									label=""
									resetError={() => setIsValidCruiseMode(true)}
									error={!isValidCruiseMode}
									onChange={setCruiseMode}
									selectedOption={cruiseMode}
								/>
							</div>
						</div>
					)}
					<div className={cx("formRow")}>
						<div className={cx("formColumn")}>
							<div className={cx("inputContainer")}>
								<DestinationField
									id="cruise-destination"
									label={GetDictionary("going-to-sov")}
									selected={destinationSelection}
									error={!isValidDestination}
									resetError={() => setIsValidDestination(true)}
									onChange={setDestination}
									language={language}
									cruiseMode={cruiseMode}
								/>
							</div>
						</div>
						<div className={cx("formColumn")}>
							<div className={cx("inputContainer")}>
								<CruiseLineField
									id="cruise-line"
									label={GetDictionary("cruise-line")}
									selected={cruiseLineSelection}
									error={!isValidCruiseLine}
									resetError={() => setIsValidCruiseLine(true)}
									onChange={setCruiseLine}
									destination={destinationSelection}
									cruiseMode={cruiseMode}
								/>
							</div>
						</div>
						<div className={cx("formColumn")}>
							<div className={cx("inputContainer")}>
								<DateField
									id="cruise-date"
									label={dictionary.when}
									language={language}
									selected={departureDay}
									minDate={dayjs().startOf("day")}
									maxDate={
										config.calendar.maxDate
											? dayjs().startOf("day").add(config.calendar.maxDate, "days")
											: dayjs().startOf("day").add(365, "days")
									}
									onChange={setDepartureDay}
								/>
							</div>
						</div>
						<div className={cx("formColumn")}>
							<div className={cx("inputContainer")}>
								<DurationField
									id="cruise-duration"
									label={GetDictionary("how-many-days")}
									selected={durationSelection}
									data={durationData}
									error={!isValidDuration}
									resetError={() => setIsValidDuration(true)}
									onChange={setDuration}
								/>
							</div>
						</div>
						<div className={cx("formColumn")}>
							<div className={cx("inputContainer")}>
								<PortField
									id="cruise-port"
									label={GetDictionary("departing-port")}
									selected={portSelection}
									error={!isValidPort}
									resetError={() => setIsValidPort(true)}
									onChange={setPort}
									destination={destinationSelection}
									cruiseLine={cruiseLineSelection}
									cruiseMode={cruiseMode}
								/>
							</div>
						</div>
						<div className={cx("formColumn")}>
							<Button
								type="submit"
								full
								size="md"
								theme="primary"
								defaultStyle={true}
								className={parentStyles.searchButton}
								onClick={event => {
									handleFormSubmission(event, event.ctrlKey || event.metaKey);
								}}
							>
								{GetDictionary("search")}
							</Button>
						</div>
					</div>
				</form>
			</div>
		</React.Fragment>
	);
};

Cruises.propTypes = {
	language: PropTypes.oneOf(["en", "fr"]).isRequired,
	config: PropTypes.shape({
		calendar: PropTypes.shape({
			startDate: PropTypes.string,
			maxDate: PropTypes.number,
			defaultMin: PropTypes.number,
		}),
		duration: PropTypes.arrayOf(["0-9999", "1-2", "3-6", "7-9", "10-14", "15-9999"]),
		showOceanRiverButton: PropTypes.bool,
	}),
};

Cruises.defaultProps = {
	config: {
		calendar: {
			startDate: undefined,
			maxDate: 365,
			defaultMin: 1,
		},
		duration: [],
		showOceanRiverButton: true,
	},
};

export default Cruises;
export { Cruises };
