import React from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-cycle
import { PaginatedCollection, TileSlider, TileCollection } from "../../components/CardCollection";

const CollectionConnector = ({ data }) => {
	if (!data?.cards?.length) {
		return null;
	}

	switch (data.variant) {
		case "paginated":
			return (
				<PaginatedCollection items={data.cards} cardsPerPage={data.cardsPerPage} link={data.link} />
			);
		case "slider":
			return (
				<TileSlider
					items={data.cards}
					itemsPerRow={data.itemsPerRow}
					navigationType={data.navigationType}
					paginationType={data.paginationType}
					link={data.link}
				/>
			);
		case "grid":
		default:
			return (
				<TileCollection
					items={data.cards}
					itemsPerRow={data.itemsPerRow}
					sortAlphabetically={data.sortAlphabetically}
				/>
			);
	}
};

CollectionConnector.propTypes = {
	data: PropTypes.shape({
		cards: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string,
				heading: PropTypes.string,
				headerAs: PropTypes.string,
				subheading: PropTypes.string,
				variant: PropTypes.oneOf([
					"primary",
					"primary--condensed",
					"primary--horizontal",
					"info",
					"info--horizontal",
				]),
				content: PropTypes.shape({
					raw: PropTypes.string,
				}),
				image: PropTypes.arrayOf(
					PropTypes.shape({
						public_id: PropTypes.string,
						secure_url: PropTypes.string,
					})
				),
				icon: PropTypes.string,
				link: PropTypes.shape({
					variant: PropTypes.oneOf(["primary", "secondary"]).isRequired,
					size: PropTypes.oneOf(["sm", "md", "lg"]),
					linkText: PropTypes.string,
					url: PropTypes.string.isRequired,
					target: PropTypes.oneOf(["_blank", "_self", "_parent", "_top", "EXTERNAL_MODAL"])
						.isRequired,
					image: PropTypes.arrayOf(
						PropTypes.shape({
							public_id: PropTypes.string,
							secure_url: PropTypes.string,
						})
					),
					imageAltText: PropTypes.string,
					iconName: PropTypes.string,
					type: PropTypes.oneOf(["link", "button"]),
					align: PropTypes.oneOf(["left", "center", "right"]),
					modal: PropTypes.arrayOf(PropTypes.object),
				}),
				logo: PropTypes.arrayOf(
					PropTypes.shape({
						public_id: PropTypes.string,
						secure_url: PropTypes.string,
					})
				),
			})
		).isRequired,
		variant: PropTypes.oneOf(["paginated", "grid", "slider"]).isRequired,
		sortAlphabetically: PropTypes.bool,
		theme: PropTypes.oneOf(["default", "info"]).isRequired,
		link: PropTypes.shape({
			variant: PropTypes.oneOf(["primary", "secondary"]).isRequired,
			size: PropTypes.oneOf(["sm", "md", "lg"]),
			linkText: PropTypes.string,
			url: PropTypes.string.isRequired,
			target: PropTypes.oneOf(["_blank", "_self", "_parent", "_top", "EXTERNAL_MODAL"]).isRequired,
			image: PropTypes.arrayOf(
				PropTypes.shape({
					public_id: PropTypes.string,
					secure_url: PropTypes.string,
				})
			),
			imageAltText: PropTypes.string,
			iconName: PropTypes.string,
			type: PropTypes.oneOf(["link", "button"]),
			align: PropTypes.oneOf(["left", "center", "right"]),
			modal: PropTypes.arrayOf(PropTypes.object),
		}),
		cardsPerPage: PropTypes.number.isRequired,
		itemsPerRow: PropTypes.oneOf([2, 3, 4, 5, 6]).isRequired,
		navigationType: PropTypes.oneOf(["true", "false", "desktopOnly"]).isRequired,
		paginationType: PropTypes.oneOf(["true", "false", "desktopOnly"]).isRequired,
		title: PropTypes.string,
	}).isRequired,
};

export default CollectionConnector;
export { CollectionConnector };
