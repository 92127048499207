/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import SwiperCore, {
	A11y,
	Autoplay,
	EffectFade,
	Keyboard,
	Navigation,
	Lazy,
	Thumbs,
	Zoom,
	Pagination,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { SliderNavigation } from "./Navigation/SliderNavigation";
import { SliderSlide } from "./Slide/SliderSlide";
import { CardLogo } from "../../../components/Card";
import * as navigationStyles from "./Navigation/SliderNavigation.module.scss";
import "../Swiper/Swiper.scss";
import * as styles from "./MainSlider.module.scss";

SwiperCore.use([Navigation, A11y, Autoplay, EffectFade, Thumbs, Zoom, Keyboard, Pagination, Lazy]);

const MainSlider = ({
	logo,
	slides,
	a11y,
	navigationClasses,
	className,
	thumbsSlider,
	setMainSlider,
	slideSelected,
	pagination,
	slideClasses,
	isModal,
}) => {
	const [isSwiperLoaded, setIsSwiperLoaded] = useState(false);
	const swiperRef = useRef();
	const onClick = () => {
		if (slideSelected) slideSelected(swiperRef.current?.activeIndex); // Swiper slides are stacked, only opacity makes it fade in and fade out. On click, only the first slide clicked. To fix the issue on click, we pick the active slide
	};
	return (
		<Swiper
			className={cx(className, styles.mainSlider, "MediaGallery_MainSlider")}
			pagination={{
				el: ".swiper-pagination",
				type: "custom",
				// TODO:: RCL
				renderCustom: pagination,
			}}
			onSwiper={swiper => {
				swiperRef.current = swiper;
				setIsSwiperLoaded(true);
				setMainSlider(swiper);
			}}
			zoom={true}
			/* onSlideChangeTransitionEnd={swiper => {
				setActiveIndex(swiper.activeIndex);
			}} */
			thumbs={{ swiper: thumbsSlider }}
			navigation={{
				nextEl: `.${navigationStyles.buttonNext}`,
				prevEl: `.${navigationStyles.buttonPrev}`,
			}}
			keyboard={{
				enabled: true,
				onlyInViewport: true,
				pageUpDown: false,
			}}
			a11y={a11y}
			loop={true}
			preloadImages={false}
			preventClicks={false}
			shortSwipes={false}
			longSwipesRatio={0.03}
			longSwipesMs={100}
			watchSlidesVisibility={true}
			lazy={{
				loadPrevNext: true,
			}}
			observer="true"
			grabCursor={true}
		>
			{slides?.map((slide, index) => (
				<SwiperSlide key={slide.id} zoom className="swiper-zoom-container">
					{({ isVisible }) => (
						<SliderSlide
							image={slide}
							tabIndex={isVisible ? 0 : -1}
							index={index + 1}
							slideSelected={slideSelected}
							onClick={onClick}
							className={slideClasses}
							isModal={isModal}
							isLazy={true}
						/>
					)}
				</SwiperSlide>
			))}
			{logo && <CardLogo logo={logo} style={{ zIndex: 1 }} />}
			<SliderNavigation
				slot="container-end"
				swiperRefLoaded={isSwiperLoaded}
				swiperRef={swiperRef}
				navigationClasses={navigationClasses}
			/>
			<div className="swiper-pagination" />
		</Swiper>
	);
};

MainSlider.propTypes = {
	logo: PropTypes.arrayOf(PropTypes.object),
	slides: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string,
			subTitle: PropTypes.string,
			desktopImage: PropTypes.shape({
				src: PropTypes.string.isRequired,
				srcSet: PropTypes.string.isRequired,
			}),
			mobileImage: PropTypes.shape({
				src: PropTypes.string.isRequired,
				srcSet: PropTypes.string.isRequired,
			}),
			imageTitle: PropTypes.string.isRequired,
		})
	).isRequired,
	thumbsSlider: PropTypes.object,
	setMainSlider: PropTypes.func,
	className: PropTypes.string,
	/**
	 *  Used for aria labels - please provide ALL labels
	 */
	a11y: PropTypes.shape({
		prevSlideLabel: PropTypes.string,
		nextSlideLabel: PropTypes.string,
		firstSlideLabel: PropTypes.string,
		lastSlideLabel: PropTypes.string,
		paginationBulletLabel: PropTypes.string,
		stopSliderLabel: PropTypes.string,
		startSliderLabel: PropTypes.string,
	}),
	navigationClasses: PropTypes.shape({
		button: PropTypes.string,
		prevButton: PropTypes.string,
		nextButton: PropTypes.string,
	}),
	slideSelected: PropTypes.func,
	pagination: PropTypes.func,
	slideClasses: PropTypes.string,
	isModal: PropTypes.bool,
};

MainSlider.defaultProps = {
	logo: {},
	className: "",
	a11y: {
		prevSlideLabel: "Previous slide",
		nextSlideLabel: "Next slide",
		firstSlideLabel: "This is the first slide",
		lastSlideLabel: "This is the last slide",
		paginationBulletLabel: "Go to slide {{index}}",
		stopSliderLabel: "Stop slider",
		startSliderLabel: "Start slider",
	},
	thumbsSlider: undefined,
	setMainSlider: null,
	navigationClasses: {},
	slideSelected: null,
	pagination: null,
	slideClasses: "",
	isModal: false,
};

export default MainSlider;
export { MainSlider };
