// extracted by mini-css-extract-plugin
export var button = "ComponentsSlider-module--button--1-ibf";
export var moreLink = "ComponentsSlider-module--moreLink--3NGa7";
export var moreLinkText = "ComponentsSlider-module--moreLinkText--AUg7w";
export var activeLink = "ComponentsSlider-module--activeLink--2B3cm";
export var moreLinkIcon = "ComponentsSlider-module--moreLinkIcon--1NCfQ";
export var bullet = "ComponentsSlider-module--bullet--1UIUM";
export var bulletActive = "ComponentsSlider-module--bulletActive--3ryOO";
export var pagination = "ComponentsSlider-module--pagination--3YIzO";
export var arrow = "ComponentsSlider-module--arrow--3SaPR";
export var slide = "ComponentsSlider-module--slide--UvmQ2";