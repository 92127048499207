import React from "react";
import PropTypes from "prop-types";
import * as styles from "./SliderSlide.module.scss";

const SliderSlide = ({ image }) => (
	<img
		className={styles.img}
		src={image?.src}
		alt={image?.alt || image.title}
		height={image?.height || 100}
		width={image?.width || 100}
	/>
);

SliderSlide.propTypes = {
	image: PropTypes.shape({
		title: PropTypes.string,
		width: PropTypes.number,
		height: PropTypes.number,
		src: PropTypes.string,
		alt: PropTypes.string,
	}).isRequired,
};

export default SliderSlide;
export { SliderSlide };
