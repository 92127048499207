/* eslint-disable import/no-cycle */
import React from "react";
import PropTypes from "prop-types";
import { CloudinaryImageAdapter } from "../../components/CloudinaryImageAdapter";
import { FluidSections } from "../../components/FluidSections";
import { useModal } from "../../components/Modal";
// eslint-disable-next-line import/no-cycle
import { RichTextConnector } from "../RichTextConnector";
import { PrimaryCard, InfoCard } from "../../components/Card";
import { Link } from "../../sharedComponents/Link";

const CardConnector = ({ data }) => {
	const Component = data.variant?.includes("info") ? InfoCard : PrimaryCard;
	const variant = data.variant.indexOf("--") > 0 ? data.variant.split("--")[1] : "normal";
	const { setIsOpen, Modal } = useModal();

	return (
		<>
			{data?.link?.modal && (
				<Modal>
					<FluidSections data={data?.link?.modal} />
				</Modal>
			)}
			<Component
				key={data.id}
				variant={variant}
				header={data.heading}
				subheading={data.subheading}
				headerAs={data.headingAs}
				LinkComponent={Link}
				icon={data.icon}
				image={{
					...CloudinaryImageAdapter({
						cloudinaryImage: data?.image?.[0],
						maxWidth: 380,
						aspectRatio: 1.77777,
					}),
				}}
				logo={
					data?.logo && {
						...CloudinaryImageAdapter({
							cloudinaryImage: data?.logo?.[0],
							maxWidth: 150,
						}),
					}
				}
				content={data.content && <RichTextConnector data={data.content} />}
				link={
					data?.link && {
						to: data?.link?.url,
						target: data?.link?.target,
						text: data?.link?.linkText,
						onClick:
							data?.link?.modal &&
							(e => {
								e.preventDefault();
								setIsOpen(true);
							}),
					}
				}
			/>
		</>
	);
};

CardConnector.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.string,
		heading: PropTypes.string,
		headingAs: PropTypes.string,
		subheading: PropTypes.string,
		variant: PropTypes.oneOf([
			"primary",
			"primary--condensed",
			"primary--horizontal",
			"info",
			"info--horizontal",
		]),
		content: PropTypes.shape({
			raw: PropTypes.string,
		}),
		image: PropTypes.arrayOf(
			PropTypes.shape({
				public_id: PropTypes.string,
				secure_url: PropTypes.string,
			})
		),
		icon: PropTypes.string,
		link: PropTypes.shape({
			variant: PropTypes.oneOf(["primary", "secondary"]).isRequired,
			size: PropTypes.oneOf(["sm", "md", "lg"]),
			linkText: PropTypes.string,
			url: PropTypes.string.isRequired,
			target: PropTypes.oneOf(["_blank", "_self", "_parent", "_top", "EXTERNAL_MODAL"]).isRequired,
			image: PropTypes.arrayOf(
				PropTypes.shape({
					public_id: PropTypes.string,
					secure_url: PropTypes.string,
				})
			),
			imageAltText: PropTypes.string,
			iconName: PropTypes.string,
			type: PropTypes.oneOf(["link", "button"]),
			align: PropTypes.oneOf(["left", "center", "right"]),
			modal: PropTypes.arrayOf(PropTypes.object),
		}),
		logo: PropTypes.arrayOf(
			PropTypes.shape({
				secure_url: PropTypes.string,
			})
		),
	}).isRequired,
};

export default CardConnector;
export { CardConnector };
