import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { SelectInput } from "@sunwing/shared-components";
import { RCL as useTranslation } from "../../../RCL";

import * as inputStyles from "../../Common/CSS/InputWrapper/InputWrapper.module.scss";
import * as ddStyles from "../../Common/CSS/Dropdown/Dropdown.module.scss";

const DurationField = ({ label, id, selected, data, error, resetError, onChange }) => {
	const GetDictionary = _key => useTranslation({ searchKey: _key });
	const [isFocus, setFocus] = useState(false);

	return (
		<SelectInput
			id={id}
			name={id}
			label={label}
			error={error}
			icon={<span className={inputStyles.chevron} />}
			className={cx(
				inputStyles.inputWrapper,
				ddStyles.dropdown,
				isFocus ? ddStyles.focus : "",
				error ? inputStyles.inputWrapperError : ""
			)}
			classNames={{
				select: inputStyles.container,
				value: ddStyles.value,
				open: cx(ddStyles.open, inputStyles.open),
				input: ddStyles.input,
				selected: ddStyles.selected,
				highlighted: ddStyles.highlighted,
				noResults: ddStyles.noResults,
				listbox: ddStyles.listbox,
			}}
			type="dropdown"
			data={data}
			selectedItem={selected === null ? data[0] : selected}
			isSelectedItem={(selectedItem, item) => selectedItem?.id === item?.id}
			placeholder={label}
			showAllSuggestionsOnClickIcon={true}
			onBlur={() => {
				setFocus(false);
			}}
			onFocus={() => {
				setFocus(true);
				resetError();
			}}
			onChange={value => {
				if (value?.value !== selected?.value) {
					resetError();
					onChange(value);
				}
			}}
			labels={{
				noResults: GetDictionary("no-results-found"),
			}}
		/>
	);
};

DurationField.propTypes = {
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	selected: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	data: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
		})
	).isRequired,
	onChange: PropTypes.func.isRequired,
	error: PropTypes.bool,
	resetError: PropTypes.func,
};

DurationField.defaultProps = {
	selected: undefined,
	error: false,
	resetError: () => {},
};

export default DurationField;
export { DurationField };
