import React, { useRef, useState } from "react";
import { arrayOf, shape, string, func } from "prop-types";
import classNames from "classnames/bind";
import SwiperCore, { A11y, Autoplay, EffectFade, Navigation, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { SliderNavigation } from "./Navigation/SliderNavigation";
import { SliderSlide } from "./Slide/SliderSlide";
import "../Swiper/Swiper.scss";
import "./ThumbSlider.scss";
import * as styles from "./ThumbSlider.module.scss";

SwiperCore.use([Navigation, A11y, Autoplay, EffectFade, Thumbs]);

const cx = classNames.bind(styles);
const ThumbSlider = ({
	slides,
	a11y,
	navigationClasses,
	className,
	setThumbsSlider,
	slideClasses,
}) => {
	const [isSwiperLoaded, setIsSwiperLoaded] = useState(false);
	const swiperRef = useRef();

	return (
		<Swiper
			onSwiper={swiper => {
				swiperRef.current = swiper;
				setIsSwiperLoaded(true);
				setThumbsSlider(swiper);
			}}
			navigation={{
				nextEl: ".thumbNavigationNextButton",
				prevEl: ".thumbNavigationPrevButton",
			}}
			className={cx(className, "thumbSlider", "MediaGallery_ThumbSlider")}
			slidesPerView="auto"
			watchSlidesVisibility={true}
			watchSlidesProgress={true}
			watchOverflow={true}
			a11y={a11y}
			spaceBetween={3}
		>
			<>
				{slides?.map(slide => (
					<SwiperSlide key={slide.id} className={cx("thumbSlide", slideClasses)}>
						<SliderSlide image={slide} />
					</SwiperSlide>
				))}
				<SliderNavigation
					slot="container-start"
					swiperRefLoaded={isSwiperLoaded}
					swiperRef={swiperRef}
					navigationClasses={navigationClasses}
				/>
			</>
		</Swiper>
	);
};

ThumbSlider.propTypes = {
	slides: arrayOf(
		shape({
			id: string.isRequired,
			title: string,
			subTitle: string,
			desktopImage: shape({
				src: string.isRequired,
				srcSet: string.isRequired,
			}),
			mobileImage: shape({
				src: string.isRequired,
				srcSet: string.isRequired,
			}),
			imageTitle: string.isRequired,
		})
	).isRequired,
	className: string,
	/**
	 *  Used for aria labels - please provide ALL labels
	 */
	a11y: shape({
		prevSlideLabel: string,
		nextSlideLabel: string,
		firstSlideLabel: string,
		lastSlideLabel: string,
		paginationBulletLabel: string,
		stopSliderLabel: string,
		startSliderLabel: string,
	}),
	setThumbsSlider: func.isRequired,
	navigationClasses: shape({
		button: string,
		prevButton: string,
		nextButton: string,
	}),
	slideClasses: string,
};

ThumbSlider.defaultProps = {
	className: "",
	a11y: {
		prevSlideLabel: "Previous slide",
		nextSlideLabel: "Next slide",
		firstSlideLabel: "This is the first slide",
		lastSlideLabel: "This is the last slide",
		paginationBulletLabel: "Go to slide {{index}}",
		stopSliderLabel: "Stop slider",
		startSliderLabel: "Start slider",
	},
	navigationClasses: {},
	slideClasses: "",
};

export default ThumbSlider;
export { ThumbSlider };
