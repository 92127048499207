import React from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-cycle
import { FluidSections } from "../../FluidSections";
// eslint-disable-next-line import/no-cycle
import { CardsContainerSlide } from "../../CardsContainer";

// TODO:: Task 99842: CardCollection, Tile Slider and Paginated collection Update

const TileSlider = ({ items, itemsPerRow }) =>
	items && (
		<CardsContainerSlide slidesPerView={itemsPerRow}>
			{items.map(item => (
				<FluidSections key={item.id} data={[item]} useWrapper={false} />
			))}
		</CardsContainerSlide>
	);

TileSlider.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			sys: PropTypes.shape({
				contentType: PropTypes.shape({
					sys: PropTypes.shape({
						id: PropTypes.string.isRequired,
					}).isRequired,
				}).isRequired,
			}).isRequired,
		})
	).isRequired,
	itemsPerRow: PropTypes.oneOf([1, 2, 3, 4]),
};

TileSlider.defaultProps = {
	itemsPerRow: 3,
};

export default TileSlider;
export { TileSlider };
