// extracted by mini-css-extract-plugin
export var sovIcon = "Footer-module--sov-icon--1FTWv";
export var sovIconAdd = "Footer-module--sov-icon-add--3ggI2";
export var sovIconAlertO = "Footer-module--sov-icon-alert--o--3URiV";
export var sovIconAlert = "Footer-module--sov-icon-alert--uZo4K";
export var sovIconArrowDouble = "Footer-module--sov-icon-arrow-double--33bhF";
export var sovIconArrowLeft = "Footer-module--sov-icon-arrow-left--3v1KE";
export var sovIconArrowRight = "Footer-module--sov-icon-arrow-right--3c3U6";
export var sovIconArrowThickDown = "Footer-module--sov-icon-arrow-thick-down--180Ao";
export var sovIconArrowThickUp = "Footer-module--sov-icon-arrow-thick-up--1gCgK";
export var sovIconCalendarAlt = "Footer-module--sov-icon-calendar-alt--10dTg";
export var sovIconCalendar = "Footer-module--sov-icon-calendar--1CFFj";
export var sovIconCheck = "Footer-module--sov-icon-check--QD4Fd";
export var sovIconChecked = "Footer-module--sov-icon-checked--34_ze";
export var sovIconCheckmark = "Footer-module--sov-icon-checkmark--UH9Lb";
export var sovIconCloseN = "Footer-module--sov-icon-close--n--3HWhE";
export var sovIconClose = "Footer-module--sov-icon-close--jK2BY";
export var sovIconCruiseAlt = "Footer-module--sov-icon-cruise-alt--1PiPS";
export var sovIconCruise = "Footer-module--sov-icon-cruise--229ko";
export var sovIconEurope = "Footer-module--sov-icon-europe--2xp6_";
export var sovIconExternal = "Footer-module--sov-icon-external--3Q6Gu";
export var sovIconFacebook = "Footer-module--sov-icon-facebook--e8YP_";
export var sovIconFaq = "Footer-module--sov-icon-faq--HtXpY";
export var sovIconFavorite = "Footer-module--sov-icon-favorite--D0WLI";
export var sovIconFilters = "Footer-module--sov-icon-filters--3AlN4";
export var sovIconFlight = "Footer-module--sov-icon-flight--Oqo3o";
export var sovIconFlights = "Footer-module--sov-icon-flights--2Clre";
export var sovIconGlobe = "Footer-module--sov-icon-globe--2iZAF";
export var sovIconHotel = "Footer-module--sov-icon-hotel--1WJTL";
export var sovIconInclusive = "Footer-module--sov-icon-inclusive--ZZeAT";
export var sovIconInformation = "Footer-module--sov-icon-information--3fRUW";
export var sovIconInstagram = "Footer-module--sov-icon-instagram--YVfyz";
export var sovIconLastmin = "Footer-module--sov-icon-lastmin--nKd_D";
export var sovIconMap = "Footer-module--sov-icon-map--3YLuX";
export var sovIconMenu = "Footer-module--sov-icon-menu--3jXrd";
export var sovIconMinusN = "Footer-module--sov-icon-minus--n--1Yj5S";
export var sovIconParking = "Footer-module--sov-icon-parking--326UF";
export var sovIconPhone = "Footer-module--sov-icon-phone--1vUIp";
export var sovIconPinterest = "Footer-module--sov-icon-pinterest--2Ufqj";
export var sovIconPlusN = "Footer-module--sov-icon-plus--n--3ur9Q";
export var sovIconStarHalf = "Footer-module--sov-icon-star-half--3e--z";
export var sovIconStar = "Footer-module--sov-icon-star--PbnqK";
export var sovIconTimer = "Footer-module--sov-icon-timer--3IxHq";
export var sovIconTravel = "Footer-module--sov-icon-travel--3sFe-";
export var sovIconTwitter = "Footer-module--sov-icon-twitter--2EY-5";
export var sovIconUplift = "Footer-module--sov-icon-uplift--1vr_W";
export var sovIconVideo = "Footer-module--sov-icon-video--iquBL";
export var sovIconYoutube = "Footer-module--sov-icon-youtube--1rFwB";
export var sovIconSearch = "Footer-module--sov-icon-search--K2Wfx";
export var sovIconComplaints = "Footer-module--sov-icon-complaints--fRv8C";
export var sovIconCustomerAssistance = "Footer-module--sov-icon-customer-assistance--2hhTR";
export var sovIconReview = "Footer-module--sov-icon-review--34mWd";
export var sovIconEmailO = "Footer-module--sov-icon-email--o--x2-DL";
export var sovIconRefreshO = "Footer-module--sov-icon-refresh--o--mVMOa";
export var sovIconArrowThickRight = "Footer-module--sov-icon-arrow-thick-right--BobLk";
export var footer = "Footer-module--footer--3mBiF";
export var footerMobilePadding = "Footer-module--footerMobilePadding--1Hd3G";
export var footerSections = "Footer-module--footerSections--33_JS";
export var footerSection = "Footer-module--footerSection--1bAU6";
export var footerSectionTitle = "Footer-module--footerSectionTitle--2gQZp";
export var footerSectionContent = "Footer-module--footerSectionContent---Vut0";
export var footerSites = "Footer-module--footerSites--3Jz79";
export var footerSites__title = "Footer-module--footerSites__title--2_uN0";
export var footerCopyright = "Footer-module--footerCopyright--2qge0";
export var footerCorps = "Footer-module--footerCorps--1QfI5";
export var footerCorpsImage = "Footer-module--footerCorpsImage--GtDQ1";
export var footerBottom = "Footer-module--footerBottom--3pMYV";
export var footerLang = "Footer-module--footerLang--oQxR8";
export var footerLangIcon = "Footer-module--footerLangIcon--vI-JM";
export var social = "Footer-module--social--1qeaN";
export var socialTitle = "Footer-module--socialTitle--3M1L3";
export var socialNav = "Footer-module--socialNav--2CXtT";
export var footerLinks = "Footer-module--footerLinks--2gkQs";
export var langToggleCurrent = "Footer-module--langToggleCurrent--1-Qrl";
export var langToggleAlt = "Footer-module--langToggleAlt--1WcXX";
export var activeLink = "Footer-module--activeLink---sxEw";
export var tabLabel = "Footer-module--tabLabel--1QAXZ";
export var tab = "Footer-module--tab--4Dixk";
export var tabLabelChevron = "Footer-module--tabLabelChevron--3AM2f";
export var tabContent = "Footer-module--tabContent--3LP3a";
export var toTop = "Footer-module--toTop--3RGqL";