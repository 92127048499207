import React from "react";
import PropTypes from "prop-types";
import { VideoPlayer } from "../../components/VideoPlayer/VideoPlayer";

const getPosterURL = (poster, video) => {
	/* TODO:: I am very dirty clean me!!!
	 This method of getting posterURL enforce by video team because they just have
	 posters in same folder with bc_id in meta data.
	 */
	if (poster?.[0]?.secure_url) {
		return poster.secure_url;
	}

	const videoUrl = video?.[0]?.secure_url;
	const videoBrightCoveId = video?.[0]?.metadata?.zz_bc_id;

	if (!videoBrightCoveId && videoUrl) {
		// If video is not tagged with old BrightCove id, generate default poster using video
		return video.replace(/\.\s{3,4}$/i, ".jpg");
	}

	let baseURL = videoUrl.substr(0, videoUrl.lastIndexOf("/") + 1);
	baseURL = baseURL.replace("video/upload", "image/upload");
	return `${baseURL}/${videoBrightCoveId}_poster.jpg`;
};

const VideoPlayerConnector = ({ data }) => (
	<VideoPlayer
		lang={data.node_locale.toLowerCase().substring(0, 2)}
		publicId={data?.video?.[0]?.public_id}
		posterUrl={getPosterURL(data?.posterUrl, data?.video)}
	/>
);

VideoPlayerConnector.propTypes = {
	data: PropTypes.shape({
		video: PropTypes.arrayOf(
			PropTypes.shape({
				secure_url: PropTypes.string.isRequired,
				metadata: PropTypes.shape({
					zz_bc_id: PropTypes.string,
				}),
				public_id: PropTypes.string.isRequired,
			}).isRequired
		).isRequired,
		posterUrl: PropTypes.arrayOf(
			PropTypes.shape({
				secure_url: PropTypes.string.isRequired,
				metadata: PropTypes.shape({
					zz_bc_id: PropTypes.string,
				}),
			}).isRequired
		).isRequired,
		node_locale: PropTypes.string.isRequired,
	}).isRequired,
};

export default VideoPlayerConnector;
export { VideoPlayerConnector };
