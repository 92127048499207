import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { ComponentsSlider as ComponentsSliderComponent } from "@sunwing/shared-components";
import "@sunwing/shared-components/dist/ComponentsSlider/index.css";
import { Icon } from "../Icons";
import * as styles from "./ComponentsSlider.module.scss";

const defaultLabels = {
	prevSlideLabel: "Previous slide",
	nextSlideLabel: "Next slide",
	firstSlideLabel: "This is the first slide",
	lastSlideLabel: "This is the last slide",
	paginationBulletLabel: "Go to slide {{index}}",
};

const ComponentsSliderNavigationType = {
	true: true,
	false: false,
	desktopOnly: "desktopOnly",
};

const ComponentsSliderPaginationType = {
	true: true,
	false: false,
	desktopOnly: "desktopOnly",
};

const getBreakpoints = (slidesPerView, breakpoints) => {
	if (slidesPerView && !breakpoints) {
		switch (slidesPerView) {
			case 2:
				return {
					320: {
						slidesPerView: 2,
						spaceBetween: 12,
					},
					769: {
						slidesPerView: 2,
						spaceBetween: 16,
					},
				};
			case 3:
				return {
					320: {
						slidesPerView: 1,
						spaceBetween: 12,
					},
					576: {
						slidesPerView: 2,
						spaceBetween: 12,
						centeredSlides: false,
					},
					769: {
						slidesPerView: 3,
						spaceBetween: 16,
						centeredSlides: false,
					},
				};
			default:
				return null;
		}
	}
	return breakpoints;
};

const ComponentsSlider = ({
	slides,
	labels: providedLabels,
	className,
	classNames,
	theme,
	navigationType,
	paginationType,
	link,
	renderLink,
	boxShadow,
	spaceBetweenSlides,
	breakpoints,
	slidesPerView,
	swiperOptions,
}) => {
	const labels = { ...defaultLabels, ...providedLabels };

	return (
		<ComponentsSliderComponent
			slides={slides}
			className={className}
			classNames={{ ...classNames, slide: styles.slide }}
			labels={labels}
			navigationType={navigationType}
			navigationClasses={{
				button: cx(styles.button, {
					[styles[`button--${theme}`]]: theme && styles[`button--${theme}`],
				}),
			}}
			navigationIcons={{
				prevIcon: <Icon name="arrow-left" className={styles.arrow} />,
				nextIcon: <Icon name="arrow-right" className={styles.arrow} />,
			}}
			paginationClasses={{
				bullet: styles.bullet,
				bulletActive: styles.bulletActive,
				pagination: styles.pagination,
			}}
			paginationType={paginationType}
			moreLink={
				link &&
				renderLink(link, {
					children: (
						<span>
							<span className={styles.moreLinkText}>{link.text}</span>{" "}
							<Icon name="arrow-right" className={styles.moreLinkIcon} />
						</span>
					),
					className: cx(styles.moreLink, {
						[styles[`moreLink--${theme}`]]: theme && styles[`moreLink--${theme}`],
					}),
				})
			}
			boxShadow={boxShadow}
			spaceBetweenSlides={spaceBetweenSlides}
			breakpoints={getBreakpoints(slidesPerView, breakpoints)}
			slidesPerView={slidesPerView}
			swiperOptions={swiperOptions}
		/>
	);
};

ComponentsSlider.propTypes = {
	slides: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			view: PropTypes.node.isRequired,
		}).isRequired
	).isRequired,
	/**
	 *  Used for aria labels
	 */
	labels: PropTypes.shape({
		prevSlideLabel: PropTypes.string,
		nextSlideLabel: PropTypes.string,
		firstSlideLabel: PropTypes.string,
		lastSlideLabel: PropTypes.string,
		paginationBulletLabel: PropTypes.string,
	}),
	className: PropTypes.string,
	classNames: PropTypes.shape({
		swiper: PropTypes.string,
		slide: PropTypes.string,
	}),
	theme: PropTypes.string,
	navigationType: PropTypes.oneOf([true, false, "desktopOnly"]),
	paginationType: PropTypes.oneOf([true, false, "desktopOnly"]),
	link: PropTypes.shape({
		text: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired,
		target: PropTypes.oneOf(["_self", "_blank", "_parent", "_top"]),
	}),
	renderLink: PropTypes.func,
	boxShadow: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.number,
		PropTypes.shape({
			x: PropTypes.number,
			y: PropTypes.number,
			blur: PropTypes.number,
			spread: PropTypes.number,
			opacity: PropTypes.number,
		}),
	]),
	/**
	 *  If boxShadow is used, we automatically multiple boxShadow by 2 as the base spacing so the shadows don't overlap each other
	 *  So specifying 10 where there is a 10px box shadow would result in the actual spacing being 20
	 */
	spaceBetweenSlides: PropTypes.number,
	/**
	 * Overrides slidesPerView and spaceBetweenSlides
	 */
	breakpoints: PropTypes.objectOf(
		PropTypes.shape({
			slidesPerView: PropTypes.number,
			spaceBetween: PropTypes.number,
			centeredSlides: PropTypes.bool,
		})
	),
	/**
	 * If supplying breakpoints, this will be used as the default size
	 */
	slidesPerView: PropTypes.number,
	/**
	 * All options from Swiper is passed through: https://swiperjs.com/swiper-api
	 */
	swiperOptions: PropTypes.object,
};

ComponentsSlider.defaultProps = {
	labels: defaultLabels,
	className: undefined,
	classNames: undefined,
	theme: undefined,
	navigationType: ComponentsSliderNavigationType.true,
	paginationType: ComponentsSliderPaginationType.desktopOnly,
	link: undefined,
	renderLink: (link, { className, children }) => (
		<a href={link.url} target={link.target} className={className}>
			{children}
		</a>
	),
	boxShadow: false,
	spaceBetweenSlides: 10,
	breakpoints: undefined,
	slidesPerView: 1,
	swiperOptions: undefined,
};

export default ComponentsSlider;
export { ComponentsSlider, ComponentsSliderNavigationType, ComponentsSliderPaginationType };
